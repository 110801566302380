import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg2 from "../../Assets/bobsail.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I love coastal geology and technology and am always looking for ways to blend the two!
              <br />
              <br />I primarily code in 
              <i>
                <b className="purple"> Python and Javascript. </b>
              </i>
              <br />
              <br />
              My interest's are in&nbsp;
              <i>
                <b className="purple">backend API design, ETL pipelines, and system and data optimizations.</b> I also love learning new techologies and using tech to help fuel my hobbies.
              </i>
              <br />
              <br />
              I primarily use
              <i>
                <b className="purple"> React.js and Next.js </b>
              </i>
              for frontend frameworks.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg2} className="img-fluid" alt="avatar" style={{ borderRadius: "20px"}}/>
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/NateVanArendonk"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/nathan-vanarendonk-18329889/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
