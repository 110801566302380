import React from "react";
import Card from "react-bootstrap/Card";
import { FaSkiing } from "react-icons/fa";
import { FaBiking } from "react-icons/fa";
import { MdKitesurfing } from "react-icons/md";
import { MdOutlinePiano } from "react-icons/md";
import { FaCoffee } from "react-icons/fa";


function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi everyone, I am <span className="purple">Nate VanArendonk. </span>
            <br />
            <br />
            I am a software engineer at Netsmart working on our product and analytics platform. I primarily work as python backend developer but enjoy working in React.js and Next.js.

            <br />
            <br />
            Prior to software engineering I was a research scientist with the United States Geological Survey,
            working on coastal and fluvial hazard modeling in Washington State. 
            I built and ran models to evaluate how climate change will impact flooding hazards in the coastal zone 
            and flood plains in Western Washington.
            <br />
            <br />
            Outside of programming, I'm addicted to:
          </p>
          <ul>
            <li className="about-activity">
              <FaSkiing /> Skiing
            </li>
            <li className="about-activity">
              <FaBiking /> Mountain biking
            </li>
            <li className="about-activity">
              <MdKitesurfing /> Wing foiling
            </li>
            <li className="about-activity">
              <MdOutlinePiano /> Playing music on my synths
            </li>
            <li className="about-activity">
              <FaCoffee /> Coffee
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
