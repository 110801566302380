import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
// import Particle from "../Particle";
import windLine from "../../Assets/Projects/windline.png";
import sailSelector from "../../Assets/Projects/sail_selector.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      {/* <Particle /> */}
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few things I've spun up recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "80px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={windLine}
              isBlog={false}
              title="The Windline"
              description="Online resource for hi-res wind forecasts in Puget Sound. Focused on water sports, specifically wing foiling and kiteboarding."
              ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://thewindline.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sailSelector}  
              isBlog={false}
              title="Sail Selector"
              description="Resource to help buyers find the right wing foiling wing given their weight and experience."
              ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://www.sailselector.com/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
